require('vanilla-cookieconsent');
const $ = require("jquery");
let defaultOptions;
import Routing from './../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min';
const routes = require('./../../../public/js/fos_js_routes.json');
Routing.setRoutingData(routes);

$(function () {
  ozInitCookieConsent({
    rgpdEmail: 'dpo@oyat-france.com',
    politiqueCookie: Routing.generate('LEGAL_MENTIONS'),
  });
})

function ozInitCookieConsent(options = {}) {

  if (!options.rgpdEmail) {
    throw 'Option "rgpdEmail" is required (oz cookieconsent.js)';
  }
  if (!options.politiqueCookie) {
    throw 'Option "politiqueCookie" is required (oz cookieconsent.js)';
  }

  // obtain cookieconsent plugin
  var cookieconsent = initCookieConsent();

  defaultOptions = getDefaultOption(options);

  // run plugin with config object
  cookieconsent.run({
    autorun: true,
    current_lang: 'fr',
    theme_css: '',
    autoclear_cookies: true,
    page_scripts: true,

    onAccept: function (cookie) {
      // ... cookieconsent accepted
    },

    onChange: function (cookie, changed_preferences) {
      // ... cookieconsent preferences were changed
    },
    gui_options: {
      consent_modal: {
        layout: 'cloud', // box/cloud/bar
        position: 'bottom right', // bottom/middle/top + left/right/center
        transition: 'slide', // zoom/slide
      },
    },
    languages: {
      fr: {
        consent_modal: {
          title: ozCookieGetValue(options, 'consentModalTitle'),
          description: ozCookieGetValue(options, 'consentModalDescription'),
          primary_btn: {
            text: ozCookieGetValue(options, 'consentModalAccept'),
            role: 'accept_all' // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: ozCookieGetValue(options, 'consentModalSettings'),
            role: 'settings' // 'settings' or 'accept_necessary'
          }
        },
        settings_modal: {
          title: ozCookieGetValue(options, 'settingsTitle'),
          save_settings_btn: ozCookieGetValue(options, 'settingsSave'),
          accept_all_btn: ozCookieGetValue(options, 'settingsAcceptAll'),
          reject_all_btn: ozCookieGetValue(options, 'settingsRefuseAll'), // optional, [v.2.5.0 +]
          cookie_table_headers: [
            {col1: 'Nom'},
            {col2: 'Domaine'},
            {col3: 'Expiration'},
            {col4: 'Description'},
            {col5: 'Type'}
          ],
          blocks: [
            {
              title: ozCookieGetValue(options, 'cookieUsageTitle'),
              description: ozCookieGetValue(options, 'cookieUsageDescription'),
            }, {
              title: ozCookieGetValue(options, 'strictlyCookiesTitle'),
              description: ozCookieGetValue(options, 'strictlyCookiesDescription'),
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true
              },
            }, {
              title: ozCookieGetValue(options, 'analyticsCookiesTitle'),
              description: ozCookieGetValue(options, 'analyticsCookiesDescription'),
              toggle: {
                value: 'analytics',
                enabled: false,
                readonly: false
              },
              cookie_table: [
                {
                  col1: '^_ga',
                  col2: 'google.com',
                  col3: '2 ans',
                  col4: 'Ce cookie est défini par Google Analytics.',
                  col5: 'Cookie permanent',
                  is_regex: true
                },
                {
                  col1: '_gid',
                  col2: 'google.com',
                  col3: '1 jour',
                  col4: 'Ce cookie est défini par Google Analytics.',
                  col5: 'Cookie permanent'
                }
              ]
            }, {
              title: ozCookieGetValue(options, 'moreInformationTitle'),
              description: ozCookieGetValue(options, 'moreInformationDescription'),
            }
          ]
        }
      }
    }
  });
}

function getDefaultOption(options) {
  return {
    consentModalTitle: 'J\'utilise des cookies',
    consentModalDescription: `Ce site utilise des cookies pour assurer son bon fonctionnement. Ces derniers ne seront définis qu\après approbation. Pour en savoir plus, rendez-vous sur notre <a aria-label="Politique de cookies" class="cc-link" href="${options.politiqueCookie}">Politique de cookies</a>.`,
    consentModalAccept: 'Accepter',
    consentModalSettings: 'Préférences',

    settingsTitle: 'Gérer les préférences des cookies',
    settingsSave: 'Enregistrer les préférences',
    settingsAcceptAll: 'Tout accepter',
    settingsRefuseAll: 'Tout refuser',

    cookieUsageTitle: 'Utilisation des cookies',
    cookieUsageDescription: `J'utilise des cookies pour assurer les fonctionnalités de base du site web et pour améliorer votre expérience en ligne. Vous pouvez choisir pour chaque catégorie d\'accepter ou de refuser quand vous le souhaitez sur notre <a aria-label="Politique de cookies" class="cc-link" href="${options.politiqueCookie}">Politique de cookies</a>.`,

    strictlyCookiesTitle: 'Cookies strictement nécessaires',
    strictlyCookiesDescription: 'Ces cookies sont essentiels au bon fonctionnement de mon site web. Sans ces cookies, le site web ne fonctionnerait pas correctement.',

    analyticsCookiesTitle: 'Cookies d\'analyse',
    analyticsCookiesDescription: 'Ces cookies collectent des informations sur la façon dont vous utilisez le site web, les pages que vous avez visitées et les liens sur lesquels vous avez cliqué. Toutes ces données sont rendues anonymes et ne peuvent être utilisées pour vous identifier.',

    moreInformationTitle: 'Plus d\'informations',
    moreInformationDescription: `Pour des questions et/ou des commentaires sur notre politique de cookies, vous pouvez nous contacter par mail <a class="cc-link" href="mailto:${options.rgpdEmail}">${options.rgpdEmail}</a>.`
  };
}

function ozCookieGetValue(options, key) {
  return options[key] ? options[key] : defaultOptions[key];
}
